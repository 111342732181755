<template>
  <div>
    <section class="top-bottom-padding review mt-5">
      <div class="text-center">
        <b-spinner v-if="loading"></b-spinner>
      </div>
      <v-row class="mt-10 pr-3 pl-2 mb-3" v-if="company_detail">
        <v-col cols="12" lg="9" md="9">
          <v-card>
            <v-row class="text-lg-left text-center">
              <v-col cols="12" sm="2" class="ml-lg-0">
                <v-avatar size="130" class="m-2">
                  <v-img :src="company_detail['company_logo']"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="6" class="mt-0 pt-lg-8">
                <v-list-item color="rgba(0, 0, 0, .4)">
                  <v-list-item-content class="pb-1">
                    <v-list-item-title class="text-1">{{
                      company_detail["company_name"]
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="sub-text">{{
                      company_detail["tag_line"]
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-col class="pt-0 pl-4">
                  {{ company_detail["rating"] }}
                  <b-form-rating
                    v-model="company_detail['rating']"
                    class="myrating"
                    readonly
                    inline
                  ></b-form-rating>
                  <span class="txt-brown"
                    >{{ company_detail["review"] }} Reviews</span
                  >
                </v-col>
              </v-col>
              <v-col cols="12" sm="4" class="text-center align-center pt-sm-15 pt-md-10 pt-lg-15">
                <a v-if="company_detail['website']" :href="company_detail['website']" target="_blank"
                  ><b-button variant="dark">
                    <b-icon
                      class="me-3"
                      icon="globe-2"
                      aria-hidden="true"
                    ></b-icon
                    >Visit Website</b-button
                  ></a
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="pl-5 pr-5 sub-text text-justify">
                  {{ company_detail["summary"] }}
                </p>
                <p></p>
              </v-col>
            </v-row>
            <v-row class="mt-3 mr-2 ml-2 bt-bb">
              <v-col sm="2" cols="6">
                <p class="sub-text">Hourly Rate</p>
                <p>
                  <strong>{{ company_detail["hourly_rate"] }}</strong>
                </p>
              </v-col>
              <v-col sm="2" cols="6">
                <p class="sub-text">Project Size</p>
                <p>
                  <strong>{{ company_detail["min_project_size"] }}</strong>
                </p>
              </v-col>
              <v-col sm="2" cols="6">
                <p class="sub-text">Employee</p>
                <p>
                  <strong
                    v-if="
                      company_detail.address &&
                      company_detail.address.employee_count
                    "
                    >{{ company_detail.address.employee_count }}</strong
                  >
                </p>
              </v-col>
              <v-col sm="2" class="br" cols="6">
                <p class="sub-text">Founded</p>
                <p>
                  <strong>{{ company_detail["founded_year"] }}</strong>
                </p>
              </v-col>

              <v-col
                cols="12"
                class="text-right mt-lg-5"
                sm="4"
                v-if="userId && company_detail['user_id'] != userId.id"
                ><b-button variant="outline-secondary" @click="goToReview()"
                  ><b-icon class="mr-2" icon="pencil-square"></b-icon>Write a
                  Review
                </b-button>
              </v-col>
            </v-row>

            <v-row class="mt-10 mr-lg-2 ml-lg-0 ml-0 mr-0">
              <div class="profile-nav">
                <b-tabs content-class="mt-3 sub-text">
                  <b-tab active>
                    <template slot="title" class="title">
                      <b-icon class="ml-3 mr-3" icon="server"></b-icon>Service
                      Focus Area
                    </template>
                    <div v-if="serviceLineFocusData.length > 0">
                      <h4>Service Line Focus Area</h4>
                      <PieChart
                        class="showChart"
                        :companyid="'chartdiv78'"
                        :serviceLineFocusData="serviceLineFocusData"
                        :serviceSingleChart="''"
                      ></PieChart>
                      <Chart
                        class="hideChart"
                        :companyid="'chartdiv7'"
                        :serviceLineFocusData="serviceLineFocusData"
                      ></Chart>
                    </div>
                    <div v-if="industryFocusData.length > 0">
                      <h4>Industry Focus Area</h4>
                      <PieChart
                        class="showChart"
                        :companyid="'chartdiv48'"
                        :industryFocusData="industryFocusData"
                        :serviceSingleChart="''"
                      ></PieChart>
                      <Chart
                        class="hideChart"
                        :companyid="'chartdiv4'"
                        :industryFocusData="industryFocusData"
                      ></Chart>
                    </div>
                    <div v-if="focusData.length > 0">
                      <h4>Client Size Area</h4>
                      <PieChart
                        class="showChart"
                        :companyid="'chartdiv58'"
                        :focusData="focusData"
                        :serviceSingleChart="''"
                      ></PieChart>
                      <Chart
                        class="hideChart"
                        :companyid="'chartdiv5'"
                        :focusData="focusData"
                      ></Chart>
                    </div>
                    <div v-if="specializationsFocusData.length > 0">
                      <h4>Specializations Area</h4>
                      <PieChart
                        class="showChart"
                        :companyid="'chartdiv88'"
                        :specializationsFocusData="specializationsFocusData"
                        :serviceSingleChart="''"
                      ></PieChart>
                      <Chart
                        class="hideChart"
                        :companyid="'chartdiv6'"
                        :specializationsFocusData="specializationsFocusData"
                      ></Chart>
                    </div>
                  </b-tab>
                  <b-tab
                    title="Second"
                    class="ml-lg-15 ml-5 mr-5"
                    v-if="company_detail.company_portfoilio"
                  >
                    <template slot="title" class="">
                      <b-icon class="ml-3 mr-3" icon="display"></b-icon
                      >Portfolio
                    </template>
                    <v-row
                      class="tab-bg mb-5 shadow rounded"
                      v-for="(
                        pdetail, index
                      ) in company_detail.company_portfoilio"
                      :key="index"
                    >
                      <v-row>
                        <v-col cols="12" sm="10">
                          <p>
                            <strong>{{ pdetail.title }} </strong>
                          </p>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                          <b-icon class="mr-2" icon="cast"></b-icon>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div>
                            <b-carousel
                              id="carousel-no-animation"
                              style="text-shadow: 0px 0px 2px #000"
                              no-animation
                              indicators
                              img-width="1024"
                              img-height="480"
                            >
                              <b-carousel-slide
                                :img-src="pdetail.image"
                              ></b-carousel-slide>
                            </b-carousel>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <p class="font-size-14">Amount</p>
                              <p class="font-size-14">
                                <strong>{{ pdetail.project_size }}</strong>
                              </p>
                            </v-col>
                            <v-col cols="12" sm="4"> </v-col>
                            <v-col cols="12" sm="4" v-if="pdetail.categories">
                              <p class="font-size-14">Industry</p>
                              <p class="font-size-14">
                                <strong>{{ pdetail.categories.name }}</strong>
                              </p>
                            </v-col>
                          </v-row>
                          <hr />
                          <p class="font-size-14 text-justify">
                            {{ pdetail.discription }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-row>
                  </b-tab>
                  <b-tab title="Second">
                    <template slot="title">
                      <b-icon class="ml-3 mr-3" icon="star"></b-icon>Review
                    </template>
                    <div v-if="company_detail.company_review">
                      <h4><strong>Review</strong></h4>
                      <v-row
                        v-for="(review, index) in company_detail.company_review"
                        :key="index"
                        class="border-with-radious mr-1 ml-1 pt-4"
                      >
                        <v-col cols="12" sm="2" class="pl-5">
                          <v-avatar size="60">
                            <img
                              v-bind:src="review.userdetails.profile_picture"
                              alt="John"
                            />
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="6" class="">
                          <p class="brown--text mb-0">
                            {{ review.userdetails.first_name }}
                            {{ review.userdetails.last_name }}
                          </p>
                          {{ review.rating }}
                          <b-form-rating
                            :value="review.rating"
                            class="myrating pl-0"
                            readonly
                            inline
                          ></b-form-rating>
                        </v-col>
                        <v-col cols="12" sm="4" class="text-right">
                          <p class="font-size-14">
                            Reviewed on {{ review.updated_at | formatDate }}
                          </p>
                        </v-col>
                        <!-- <v-col cols="12" sm="1" class="pt-2 pb-2 home-tab cust-height">
            <p class="mb-0">
              <v-icon class="ml-0 mr-1"> mdi-share </v-icon>Share
            </p>
          </v-col> -->
                        <v-row class="pt-2 pl-5">
                          <p>
                            <strong>{{ review.review }}</strong>
                          </p>
                          <p>
                            {{ review.company_background }}
                          </p>
                        </v-row>
                        <hr class="mt-3 mb-3" />
                        <v-row class="pl-5">
                          <v-col cols="12" sm="6" class="pr-0">
                            <p class="font-size-14 mb-1">Services</p>
                            <p>
                              <b>{{ review.type_company_work_for }}</b>
                            </p>
                          </v-col>
                          
                          <v-col cols="12" sm="6" class="text-right">
                            <b-button
                              v-b-toggle="'collapse' + review.id"
                              class="m-1"
                              style="
                                display: inline-flex;
                                padding-left: 8px;
                                padding-right: 0px;
                              "
                              >View Full Review
                              <v-icon color="white"
                                >mdi-chevron-down</v-icon
                              ></b-button
                            >
                          </v-col>
                        </v-row>
                        <b-collapse :id="'collapse' + review.id" class="">
                          <b-card class="border cust-card mr-1 ml-1 pt-0">
                            <v-row class="pt-0">
                              <v-col
                                cols="12"
                                sm="4"
                                class="border-end bg-review text-center pr-0"
                              >
                                <span class="font-size-14 text-black-50"
                                  >THE Reviewer</span
                                >
                                <h4></h4>
                                <b-avatar
                                  v-if="review.userdetails.profile_picture"
                                  class=""
                                  size="60"
                                  :src="review.userdetails.profile_picture"
                                ></b-avatar>
                                <b-avatar
                                  v-if="!review.userdetails.profile_picture"
                                  src="/public/noImage.png"
                                ></b-avatar>
                                <p class="mb-1 fw-bold">
                                  {{ review.userdetails.first_name }}
                                  {{ review.userdetails.last_name }}
                                </p>
                                <p class="mb-1 ml-2 font-size-14" v-if="review && review.company && review.company.primary_email">
                                  {{ review.company.primary_email }}
                                </p>
                                <p class="mb-1 ml-2 font-size-14" v-if="review && review.website && review.company.website">
                                  {{ review.company.website }}
                                </p>
                              </v-col>
                              <v-col cols="12" sm="5" class="pb-0 border-end">
                                <p
                                  class="
                                    font-size-14
                                    text-center
                                    mb-0
                                    text-black-50
                                    pl-5
                                  "
                                >
                                  PROJECT DETAILS
                                </p>
                                <hr />

                                <h5 class="text-center pt-2">
                                  {{ review.project_title }}.
                                </h5>
                                <v-row class=" ml-ld-1">
                                  <v-col cols="12" class="p-1 text-center  text-lg-right"  lg="5"
                                    ><span class="text-black"
                                      >Project Type:
                                    </span></v-col
                                  >
                                  <v-col cols="12" class="p-1 text-center text-lg-left" lg="7"
                                    ><p
                                      class="
                                        font-size-14
                                        mb-0
                                        text-black-50
                                        pl-lg-2
                                      "
                                    >
                                      {{ review.project_type }}
                                    </p></v-col
                                  >
                                </v-row>
                                <v-row class="ml-ld-1">
                                  <v-col cols="12" class="p-1 text-center  text-lg-right" lg="5"
                                    ><span class="text-black"
                                      >Start Time:
                                    </span></v-col
                                  >
                                  <v-col cols="12" class="p-1 text-center text-lg-left" lg="7"
                                    ><p
                                      class="
                                        font-size-14
                                        mb-0
                                        text-black-50
                                        pl-lg-2
                                      "
                                    >
                                      {{ review.project_start | formatDate }}
                                    </p></v-col
                                  >
                                </v-row>
                                <v-row class="ml-ld-1">
                                  <v-col cols="12" class="p-1 text-center  text-lg-right" lg="5"
                                    ><span class="text-black"
                                      >End Time:
                                    </span></v-col
                                  >
                                  <v-col cols="12" class="p-1 text-center text-lg-left" lg="7"
                                    ><p
                                      class="
                                        font-size-14
                                        mb-0
                                        text-black-50
                                        pl-lg-2
                                      "
                                    >
                                      {{ review.project_end | formatDate }}
                                    </p></v-col
                                  >
                                </v-row>
                                <v-row class=" pb-3 ml-ld-1">
                                  <v-col cols="12" class="p-1 text-center  text-lg-right" lg="5"
                                    ><span class="text-black"
                                      >Website URL:
                                    </span></v-col
                                  >
                                  <v-col cols="12" class="p-1 text-center text-lg-left" lg="7"
                                    ><p
                                      class="
                                        font-size-14
                                        mb-0
                                        text-black-50
                                        pl-lg-2
                                      "
                                    >
                                      {{ review.website_url }}
                                    </p></v-col
                                  >
                                </v-row>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <p
                                  class="
                                    font-size-14
                                    text-center
                                    mb-0
                                    mt-1
                                    text-black-50
                                    pl-5
                                  "
                                >
                                  DETAIL RATING
                                </p>

                                <hr class="mt-3 mb-1" />
                                <v-row>
                                  <v-col cols="12" lg="8" md="" class="pb-0 text-md-left text-sm-center text-lg-left">
                                    <p>Quality:</p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    lg="4"
                                    class="text-lg-right text-center  pb-0"
                                  >
                                    <p>{{ review.company_solution_1 }}.0</p>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" lg="8" class="pb-0 text-md-left text-sm-center text-lg-left">
                                    <p>Ability:</p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    lg="4"
                                    class="text-lg-right text-center  pb-0"
                                  >
                                    <p>{{ review.company_solution_3 }}.0</p>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" lg="8" class="pb-0 pr-0 text-md-left text-sm-center text-lg-left">
                                    <p>Reliability:</p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    lg="4"
                                    class="text-lg-right text-center  pb-0"
                                  >
                                    <p>{{ review.company_solution_2 }}.0</p>
                                  </v-col>
                                </v-row>
                                <hr class="mb-3" />
                                <v-row>
                                  <v-col class="text-center p-0">
                                    <b-form-rating
                                      class="myrating"
                                      inline
                                      :value="review.rating"
                                    ></b-form-rating
                                  ></v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </b-card>
                        </b-collapse>
                      </v-row>
                      <!-- <v-row
                        v-for="(v, k) in company_detail.company_review"
                        :key="k"
                        class="border mb-5 shadow"
                      >
                        <v-col
                          cols="12"
                          sm="4"
                          class="border-end bg-review text-center pr-0"
                        >
                          <span class="font-size-14 text-black-50"
                            >THE REVIEWER</span
                          >
                          <h4></h4>
                          <div v-if="v.userdetails">
                            <b-avatar
                              v-if="v.userdetails.profile_picture"
                              class=""
                              size="60"
                              :src="v.userdetails.profile_picture"
                            ></b-avatar>
                            <b-avatar
                              v-if="!v.userdetails.profile_picture"
                              class=""
                              size="60"
                              src="/public/noImage.png"
                            ></b-avatar>
                          </div>

                          <p
                            class="mb-1 ml-1"
                            v-if="v.userdetails && v.userdetails.first_name"
                          >
                            {{ v.userdetails.first_name }}
                            <span>{{ v.userdetails.last_name }}</span>
                          </p>
                          <p
                            class="mb-1 fw-bold"
                            v-if="v.userdetails && v.userdetails.company_name"
                          >
                            {{ v.userdetails.company_name }}
                          </p>
                          <p
                            class="mb-1 ml-2 font-size-14"
                            v-if="v.address && v.address.address"
                          >
                            {{ v.address.address }}
                          </p>
                        </v-col>
                        <v-col cols="12" sm="5" class="pb-0 border-end">
                          <span class="font-size-14 text-black-50 pl-5"
                            >THE REVIEW</span
                          >
                          <p class="mt-5 mb-6 fw-bold pl-5">
                            " {{ v.review }}. "
                          </p>
                          <p class="font-size-14 text-black-50 pl-5">
                            {{ v.updated_at | formatDate }}
                          </p>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <span class="font-size-14 text-black-50 pl-5"
                            >OVERALL RATING</span
                          >
                          <b-form-rating
                            class="myrating"
                            inline
                            :value="v.rating"
                          ></b-form-rating>
                          <span class="txt-brown"> {{ v.rating }}</span>
                          <hr class="mt-3 mb-1" />
                          <v-row>
                            <v-col cols="12" sm="6" class="pb-0">
                              <p>Quality:</p>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-right pb-0">
                              <p>{{ v.company_solution_1 }}.0</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" class="pb-0 pt-0">
                              <p>Ability:</p>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              class="text-right pb-0 pt-0"
                            >
                              <p>{{ v.company_solution_3 }}.0</p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" class="pb-0 pt-0">
                              <p>Reliability:</p>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              class="text-right pb-0 pt-0"
                            >
                              <p>{{ v.company_solution_2 }}.0</p>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row> -->
                    </div>
                  </b-tab>
                  <b-tab title="Second">
                    <template slot="title">
                      <b-icon class="ml-3 mr-1" icon="at"></b-icon>Social Media
                    </template>
                    <div class="p-5 pt-0 mt-3 ml-5 mr-5 top-listed">
                      <b-row>
                        <b-col
                          cols="12"
                          sm="4"
                          v-if="company_detail['facebook_url']"
                        >
                          <a target="_blank">
                            <b-card
                              img-src="/image/social-bg.jpg"
                              class="
                                text-center
                                border-2-color border-radius-25
                                profille_scocial_height
                              "
                            >
                              <div class="social-logo">
                                <b-img
                                  variant="primary"
                                  src="/image/facebook.jpg"
                                  rounded="circle"
                                  class="border-2-color img-border-top-radius"
                                ></b-img>
                              </div>
                              <div class="pt-4 company-title">
                                <b-card-text class="mb-2 text-left"
                                  ><strong>Facebook</strong></b-card-text
                                >
                                <b-card-text
                                  class="mb-0 text-left font-size-12"
                                >
                                  {{
                                    company_detail["facebook_url"]
                                  }}</b-card-text
                                >
                              </div>
                            </b-card></a
                          >
                        </b-col>

                        <b-col
                          cols="12"
                          sm="4"
                          v-if="company_detail['twitter_url']"
                        >
                          <a
                            :href="company_detail['twitter_url']"
                            target="_blank"
                          >
                            <b-card
                              img-src="/image/social-bg.jpg"
                              class="
                                text-center
                                border-2-color border-radius-25
                                profille_scocial_height
                              "
                            >
                              <div class="social-logo">
                                <b-img
                                  variant="primary"
                                  src="/image/twiter.png"
                                  rounded="circle"
                                  class="border-2-color img-border-top-radius"
                                ></b-img>
                              </div>
                              <div class="pt-4 company-title">
                                <b-card-text class="mb-2 text-left"
                                  ><strong>Twitter</strong></b-card-text
                                >
                                <b-card-text
                                  class="mb-0 text-left font-size-12"
                                  >{{
                                    company_detail["twitter_url"]
                                  }}</b-card-text
                                >
                              </div>
                            </b-card></a
                          >
                        </b-col>

                        <b-col
                          cols="12"
                          sm="4"
                          v-if="company_detail['linked_in_url']"
                        >
                          <a
                            :href="company_detail['linked_in_url']"
                            target="_blank"
                          >
                            <b-card
                              img-src="/image/social-bg.jpg"
                              class="
                                text-center
                                border-2-color border-radius-25
                                profille_scocial_height
                              "
                            >
                              <div class="social-logo">
                                <b-img
                                  variant="primary"
                                  src="/image/LinkedIn.jpg"
                                  rounded="circle"
                                  class="border-2-color img-border-top-radius"
                                ></b-img>
                              </div>
                              <div class="pt-4 company-title">
                                <b-card-text class="mb-2 text-left"
                                  ><strong>LinkedIn</strong></b-card-text
                                >
                                <b-card-text
                                  class="mb-0 text-left font-size-12"
                                  >{{
                                    company_detail["linked_in_url"]
                                  }}</b-card-text
                                >
                              </div>
                            </b-card>
                          </a>
                        </b-col>
                      </b-row>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="3">
          <v-card class="bg-transparent">
            <b-card
              class="sidebar-bg text-center text-lg-left"
              title="Contact Information"
            >
              <v-row>
                <v-col sm="3" cols="2" class="text-lg-center pt-5 pr-lg-0"
                  ><b-icon icon="person" aria-hidden="true"></b-icon
                ></v-col>
                <v-col sm="9" cols="10" class="px-0 mt-2 text-left"
                  ><span
                    class="text-lg-center"
                    v-if="
                      company_detail.user_name && company_detail.user_name.name
                    "
                  >
                    {{ company_detail["user_name"]["name"] }}</span
                  ></v-col
                >
              </v-row>
              <v-row>
                <v-col sm="3" cols="2" class="text-lg-center pt-1 pr-lg-0"
                  ><b-icon icon="geo-alt" aria-hidden="true"></b-icon
                ></v-col>
                <v-col sm="9" cols="10" class="p-0 text-left"
                  ><span
                    class="text-center text-lowercase font-size-14"
                    v-if="
                      company_detail['address'] && company_detail['address']['address']
                    "
                    ><a :href="'http://maps.google.com/?q='+company_detail['address']['address']" class="text-decoration-none">{{ company_detail["address"]["address"] }}</a></span
                  ></v-col
                >
              </v-row>
              <v-row>
                <v-col sm="3" cols="2" class="text-center pr-lg-0"
                  ><b-icon icon="telephone" aria-hidden="true"></b-icon
                ></v-col>
                <v-col sm="9" cols="10" class="px-0 text-left"
                  ><span
                    class="text-center font-size-14"
                    v-if="
                      company_detail.address &&
                      company_detail.address.phone_number
                    "
                    ><a :href="'tel:'+ company_detail['address']['phone_number']" class="text-decoration-none">{{ company_detail["address"]["phone_number"] }}</a></span
                  ></v-col
                >
              </v-row>
              <hr class="hideChart" />
            </b-card>

            <b-card
              class="sidebar-bg mt-5 text-center text-lg-left"
              title="Similar Companies"
            >
              <v-row
                v-for="(items, index) in Company_List_reverse"
                :key="index"
              >
                <v-col lg="3" cols="12" class="text-lg-center px-0"
                  ><b-avatar
                    class="red"
                    :src="items.company_logo"
                    size="3rem"
                  ></b-avatar
                ></v-col>
                <v-col lg="9" cols="12" class="px-2 mt-1 text-md-left text-lg-left">
                  <span class="text-center">{{ items.company_name }}</span>
                  <v-row class="justify-md-start justify-sm-center justify-lg-start">
                    <!-- <v-col sm="2" class="pr-0 pt-4">
                      {{ items.rating }}
                    </v-col> -->
                    <v-col sm="10" class="px-2 pt-1 text-lg-left">
                      <b-form-rating
                        readonly
                        v-model="items.rating"
                        class="myrating p-0 "
                        inline
                      ></b-form-rating>
                    </v-col>
                  </v-row>
                </v-col>
                <hr />
              </v-row>

              <v-row>
                <v-col class="text-center"
                  ><span
                    ><router-link to="/listcompany">View all</router-link></span
                  ></v-col
                >
              </v-row>
            </b-card>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>



import { mapActions } from "vuex";
import Chart from "../../components/FullChart.vue";
import PieChart from "../../components/PieChart.vue";

export default {
  components: { Chart, PieChart },
  name: "Company",
  data: () => ({
    rating: 3,
    company_detail: {},
    focusData: [],
    industryFocusData: [],
    specializationsFocusData: [],
    serviceLineFocusData: [],
    Company_List_reverse: [],
    Company_List: [],
    loading: false,
    userId: "",
  }),
  mounted() {
    this.userId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    setTimeout(() => {
      this.getFocusData();
    }, 3000);
  },
  created() {
    this.getCompanyProfileDetail();
    this.loading = true;
  },
  methods: {
    ...mapActions([
      "getCompanyProfile",
      "getClientFocus",
      "getIndustryFocus",
      "getServiceLineFocus",
      "getCompanyDetail",
      "getSpecializationsFocus",
    ]),
    async getCompanyProfileDetail() {
      this.loading = true;
      await this.getCompanyProfile(this.$route.params.id)
        .then((response) => {
          if (response.data.data.company_review.length) {
            let rating = 0;
            let creview = 0;
            response.data.data.company_review.forEach((res, i) => {
              creview = parseInt(i) + 1;
              rating = parseInt(rating) + parseInt(res.rating);
            });
            response.data.data.rating =
              rating > 0
                ? rating / parseInt(response.data.data.company_review.length)
                : 0;
            response.data.data.review = creview;
          }
          this.company_detail = response.data.data;
          console.log(this.company_detail);
        })
        .finally(() => (this.loading = false));
      let cate = "";

      this.getCompanyDetail(cate).then((response) => {
        this.loading = true;
        response.data.data.forEach((value) => {
          let rating = 0;
          let review = "";
          if (value.company_review.length) {
            value.company_review.forEach((res, i) => {
              review = parseInt(i) + 1;
              rating = parseInt(rating) + parseInt(res.rating);
            });
          }
          value.rating = rating > 0 ? rating / value.company_review.length : 0;
          value.review = review;
          this.Company_List.push(value);
        });
        this.Company_List_reverse = this.Company_List.reverse().slice(0, 5);
      });
    },

    async getFocusData() {
      this.loading = true;
      await this.getClientFocus(this.company_detail.id)
        .then((res) => {
          this.loading = true;
          res.data.data.forEach((element) => {
            this.focusData.push({
              category: element.client_size.name,
              value1: element.percentage,
            });
          });
        })
        .finally(() => (this.loading = false));
      await this.getIndustryFocus(this.company_detail.id)
        .then((res) => {
          this.loading = true;
          res.data.data.forEach((element) => {
            this.industryFocusData.push({
              category: element.industry_focus.name,
              value1: element.percentage,
            });
          });
        })
        .finally(() => (this.loading = false));
      await this.getSpecializationsFocus(this.company_detail.id)
        .then((res) => {
          this.loading = true;
          res.data.data.forEach((element) => {
            this.specializationsFocusData.push({
              category: element.specialization.name,
              value1: element.percentage,
            });
          });
        })
        .finally(() => (this.loading = false));
      await this.getServiceLineFocus({company_id:this.company_detail.id,type:'profile'})
        .then((res) => {
          this.loading = true;
          res.data.data.forEach((element) => {
            this.serviceLineFocusData.push({
              category: element.categories.name,
              value1: element.percentage,
            });
          });
        })
        .finally(() => (this.loading = false));
    },
    goToReview() {
      this.$router.push({
        name: "review",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>
