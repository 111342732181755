<template>
  <div class="hello" :id="companyid" style="height: 500px"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
export default {
  name: "Chart",
  props: [
    "companyid",
    "focusData",
    "industryFocusData",
    "specializationsFocusData",
    "serviceLineFocusData",
  ],
  data: function () {
    return {
      selected: null,
      slide: 0,
      sliding: null,
      chartData: null,
    };
  },
  mounted() {
    let focusarray = [
      this.serviceLineFocusData,
      this.industryFocusData,
      this.focusData,
      this.specializationsFocusData,
    ];
    // if (this.companyid == "chartdiv4") {
    for (let index = 0; index < focusarray.length; index++) {
      const element = focusarray[index];
      if (element) {
        // let chart = am4core.create(this.companyid, am4charts.XYChart3D);
        // am4core.addLicense(this.companyid);

        // chart.paddingTop = -60;
        // chart.angle = 20;
        // chart.responsive.enabled = true;

        // // Add data
        // chart.data = element;
        // // Create axes
        // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = "category";
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.grid.template.strokeOpacity = 0;
        // categoryAxis.renderer.minGridDistance = 12;
        // categoryAxis.renderer.fontSize = 13;
        // // categoryAxis.renderer.marginTop=15;

        // let label = categoryAxis.renderer.labels.template;
        // // label.wrap = true;
        // label.maxWidth = 100;
        // label.marginTop = "10px";
        // label.marginBottom = "10px";

        // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.renderer.grid.template.strokeOpacity = 0;
        // valueAxis.min = -55;
        // valueAxis.max = 150;
        // valueAxis.strictMinMax = true;
        // valueAxis.renderer.baseGrid.disabled = true;

        // valueAxis.renderer.labels.template.adapter.add("fill", function () {
        //   return am4core.color("#fff");
        // });

        // let color = ["#955728", "#434950", "#E9E2CB", "#C49F84", "#B4B5B7"];
        // // Create series
        // let series1 = chart.series.push(new am4charts.ConeSeries());
        // series1.dataFields.valueY = "value1";
        // series1.dataFields.categoryX = "category";
        // series1.columns.template.width = am4core.percent(65);
        // series1.columns.template.strokeOpacity = 1;
        // series1.columns.template.strokeWidth = 0;

        // let image = new am4core.Image();
        // image.horizontalCenter = "middle";
        // image.width = "20%";
        // image.height = "55px";
        // // image.maxHeight = 60;
        // image.verticalCenter = "middle";
        // image.adapter.add("href", (href, target) => {
        //   let category = target.dataItem.category;
        //   if (category) {
        //     return "../image/dark.png";
        //   }
        //   return href;
        // });
        // categoryAxis.dataItems.template.bullet = image;
        // categoryAxis.renderer.labels.template.adapter.add(
        //   "dy",
        //   function (dy) {
        //     return dy + 10;
        //   }
        // );

        // series1.columns.template.adapter.add("fill", function (fill, target) {
        //   if (target.dataItem.index > 4) {
        //     let val = color[target.dataItem.index % 5];
        //     return am4core.color(val);
        //   } else {
        //     return am4core.color(color[target.dataItem.index]);
        //   }
        // });

        // let bullet1 = series1.bullets.push(new am4charts.Bullet());
        // let image1 = bullet1.createChild(am4core.Image);
        // image1.href = "../image/round.svg";
        // image1.width = 30;
        // image1.height = 22;
        // image1.horizontalCenter = "middle";
        // image1.verticalCenter = "middle";
        // image1.zIndex = -1;

        // let bullet = series1.bullets.push(new am4charts.LabelBullet());
        // bullet.label.text = "{value1}%";
        // bullet.label.fontSize = 12;
        // //bullet.label.width = 35;
        // //bullet.label.height = 15;
        // bullet.label.fontWeight = "bold";
        // //bullet.label.position = "absolute";
        // bullet.stroke = am4core.color("#FFFFFF");
        // bullet.label.zIndex = 9999;
        // bullet.label.dx = 2;

        let chart = am4core.create(this.companyid, am4charts.XYChart3D);

        chart.width = am4core.percent(100);
        chart.height = am4core.percent(70);
        // chart.paddingBottom = 20;
        //chart.paddingRight = 50;
        if (element.length == 1) {
          chart.paddingRight = am4core.percent(45);
        }
        // chart.paddingTop = "40px";
        //chart.angle = 20;
        chart.responsive.enabled = true;

        // Add data
        chart.data = element;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.fontSize = 14;
        categoryAxis.renderer.fontWeight = "bold";

        // categoryAxis.renderer.marginTop=15;

        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 130;
        // label.marginTop = "10px";
        // label.marginBottom = "10px";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // if (this.serviceLineFocusData.length == 1) {
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.baseGrid.disabled = true;
        // }
        valueAxis.renderer.labels.template.adapter.add("fill", function () {
          return am4core.color("#fff");
        });
        let color = ["#955728", "#434950", "#E9E2CB", "#C49F84", "#B4B5B7"];
        // Create series
        let series1 = chart.series.push(new am4charts.ConeSeries());
        series1.dataFields.valueY = "value1";
        series1.dataFields.categoryX = "category";

        // let valueD = "";
        if (element.length == 1) {
          series1.columns.template.width = am4core.percent(25);
        } else if (element.length == 2) {
          series1.columns.template.width = am4core.percent(25);
        } else {
          series1.columns.template.width = am4core.percent(40);
        }

        if (
          element.some(function (o) {
            return parseInt(o["value1"]) >= 90;
          })
        ) {
          series1.columns.template.paddingTop = "15px";
        }
        series1.columns.template.strokeOpacity = 1;
        series1.columns.template.strokeWidth = 0;
        // if (valueD >= 90) {
        //   series1.columns.template.paddingTop = "20px";
        // }
        series1.calculatePercent = true;

        let image = new am4core.Image();
        image.horizontalCenter = "middle";
        // image.width = "30%";
        image.height = "65px";
        image.paddingTop = "22px";
        // image.verticalCenter = "middle";
        image.adapter.add("href", (href, target) => {
          let category = target.dataItem.category;
          if (category) {
            return "../image/dark.png";
          }
          return href;
        });

        categoryAxis.dataItems.template.bullet = image;

        categoryAxis.renderer.labels.template.adapter.add("dy", function (dy) {
          return dy + 75;
        });

        series1.columns.template.adapter.add("fill", function (fill, target) {
          if (target.dataItem.index > 4) {
            let val = color[target.dataItem.index % 5];
            return am4core.color(val);
          } else {
            return am4core.color(color[target.dataItem.index]);
          }
        });

        let bullet1 = series1.bullets.push(new am4charts.Bullet());
        let image1 = bullet1.createChild(am4core.Image);
        image1.href = "../image/round.svg";
        image1.width = 50;
        image1.height = "25px";
        if (
          element.some(function (o) {
            return parseInt(o["value1"]) >= 90;
          })
        ) {
          bullet1.paddingTop = "15px";
        }
        image1.horizontalCenter = "middle";
        image1.verticalCenter = "middle";
        // if (valueD >= 90) {
        //   image1.paddingBottom = "-20px";
        // }
        image1.zIndex = -1;

        let bullet = series1.bullets.push(new am4charts.LabelBullet());
        bullet.label.text = "{value1}%";
        if (
          element.some(function (o) {
            return parseInt(o["value1"]) >= 90;
          })
        ) {
          bullet.paddingTop = "15px";
        }
        bullet.label.fontSize = 13;
        // if (valueD >= 90) {
        //   bullet.label.paddingTop = "40px";
        // }
        //bullet.label.width = 35;
        //bullet.label.height = 15;
        bullet.label.fontWeight = "bold";
        //bullet.label.position = "absolute";
        bullet.stroke = am4core.color("#FFFFFF");
        bullet.label.zIndex = 9999;
        bullet.label.dx = 2;
      }
    }

    // }
    // if (this.companyid == "chartdiv5") {
    //   this.chartData = this.industryFocusData;
    //   let chart = am4core.create(this.companyid, am4charts.XYChart3D);
    //   am4core.addLicense(this.companyid);

    //   chart.paddingTop = -60;
    //   chart.angle = 20;
    //   chart.responsive.enabled = true;

    //   // Add data
    //   chart.data = this.industryFocusData;
    //   // Create axes
    //   let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //   categoryAxis.dataFields.category = "category";
    //   categoryAxis.renderer.grid.template.location = 0;
    //   categoryAxis.renderer.grid.template.strokeOpacity = 0;
    //   categoryAxis.renderer.minGridDistance = 12;
    //   categoryAxis.renderer.fontSize = 13;
    //   // categoryAxis.renderer.marginTop=15;

    //   let label = categoryAxis.renderer.labels.template;
    //   label.wrap = true;
    //   label.maxWidth = 100;
    //   label.marginTop = "10px";
    //   label.marginBottom = "10px";

    //   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //   valueAxis.renderer.grid.template.strokeOpacity = 0;
    //   valueAxis.min = -55;
    //   valueAxis.max = 150;
    //   valueAxis.strictMinMax = true;
    //   valueAxis.renderer.baseGrid.disabled = true;

    //   valueAxis.renderer.labels.template.adapter.add("fill", function () {
    //     return am4core.color("#fff");
    //   });

    //   let color = ["#955728", "#434950", "#E9E2CB", "#C49F84", "#B4B5B7"];
    //   // Create series
    //   let series1 = chart.series.push(new am4charts.ConeSeries());
    //   series1.dataFields.valueY = "value1";
    //   series1.dataFields.categoryX = "category";
    //   series1.columns.template.width = am4core.percent(65);
    //   series1.columns.template.strokeOpacity = 1;
    //   series1.columns.template.strokeWidth = 0;

    //   let image = new am4core.Image();
    //   image.horizontalCenter = "middle";
    //   image.width = "20%";
    //   image.height = "55px";
    //   // image.maxHeight = 60;
    //   image.verticalCenter = "middle";
    //   image.adapter.add("href", (href, target) => {
    //     let category = target.dataItem.category;
    //     if (category) {
    //       return "../image/dark.png";
    //     }
    //     return href;
    //   });
    //   categoryAxis.dataItems.template.bullet = image;
    //   categoryAxis.renderer.labels.template.adapter.add("dy", function (dy) {
    //     return dy + 10;
    //   });

    //   series1.columns.template.adapter.add("fill", function (fill, target) {
    //     if (target.dataItem.index > 4) {
    //       let val = color[target.dataItem.index % 5];
    //       return am4core.color(val);
    //     } else {
    //       return am4core.color(color[target.dataItem.index]);
    //     }
    //   });

    //   let bullet1 = series1.bullets.push(new am4charts.Bullet());
    //   let image1 = bullet1.createChild(am4core.Image);
    //   image1.href = "../image/round.svg";
    //   image1.width = 30;
    //   image1.height = 22;
    //   image1.horizontalCenter = "middle";
    //   image1.verticalCenter = "middle";
    //   image1.zIndex = -1;

    //   let bullet = series1.bullets.push(new am4charts.LabelBullet());
    //   bullet.label.text = "{value1}%";
    //   bullet.label.fontSize = 12;
    //   //bullet.label.width = 35;
    //   //bullet.label.height = 15;
    //   bullet.label.fontWeight = "bold";
    //   //bullet.label.position = "absolute";
    //   bullet.stroke = am4core.color("#FFFFFF");
    //   bullet.label.zIndex = 9999;
    //   bullet.label.dx = 2;
    // }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>
